import React from 'react';

import './App.css';
import black0 from './assets/black0.gif'
import black1 from './assets/black1.gif'
import black2 from './assets/black2.gif'
import black3 from './assets/black3.gif'
import black4 from './assets/black4.gif'
import blue0 from './assets/blue0.gif'
import blue1 from './assets/blue1.gif'
import blue2 from './assets/blue2.gif'
import blue3 from './assets/blue3.gif'
import blue4 from './assets/blue4.gif'
import me0 from './assets/me0.gif'
import me1 from './assets/me1.gif'
import me2 from './assets/me2.gif'
import me3 from './assets/me3.gif'
import me4 from './assets/me4.gif'
import me5 from './assets/me5.gif'
import me6 from './assets/me6.gif'
import me7 from './assets/me7.gif'
import me8 from './assets/me8.gif'
import me9 from './assets/me9.gif'
import me10 from './assets/me10.gif'
import me11 from './assets/me11.gif'
import me12 from './assets/me12.gif'
import me13 from './assets/me13.gif'

class App extends React.Component {
  constructor(props) {
    super(props) 

    const outerGifs = [
      black0, black1, black2, black3, black4,
      blue0, blue1, blue2, blue3, blue4
    ]

    const innerGifs = [
      me0, me1, me2, me3, me4, me5, me6, me7, me8, me9, me10, me11, me12, me13
    ]


    this.state = {
      outerGifs: outerGifs,
      outerGif: outerGifs[Math.floor(Math.random() * outerGifs.length)],
      innerGifs: innerGifs,
      innerGif0: "",
      innerGif1: "",
      innerGif2: "",
      counter: 0,
      gifStyle: {
        visibility: "hidden"
      },
      titleStyle: {
        visibility: 'visible'
      }
    }
    this.containerStyle = {
      backgroundImage: 'url(' + this.state.outerGif + ')',
      height: '100%',
      backgroundSize: 'cover',
      position: 'fixed',
      top: 0,
      width: '100%',
      padding: 0, 
      margin: 0,
    }

    this.deadCenterStyle = {
      position: 'fixed',
      top: '50%',
      left: '50%',
      height: '50%',
      transform: 'translate(-50%, -50%)',
    }
  }

  updateOuter = () => {
    const outerGifs = this.state.outerGifs
    const newGif = outerGifs[Math.floor(Math.random() * outerGifs.length)]
    this.setState({
      outerGif: newGif
    })
  }

  updateInner = () => {
    console.log(this.state.counter)
    var oneInFour = this.state.counter % 4
    if (oneInFour == 2) { 
      console.log("show gifs")
      var randGifs = [];
      do {
        randGifs[randGifs.length] = this.state.innerGifs.splice(Math.floor(Math.random() * this.state.innerGifs.length), 1)[0];
      } while (randGifs.length < 3);
      console.log(randGifs)
      this.setState({
        innerGif0: randGifs[0],
        innerGif1: randGifs[1],
        innerGif2: randGifs[2],
        gifStyle: {
          visibility: "visible"
        },
        titleStyle: {
          visibility: "hidden"
        }
      })
    } else if (oneInFour == 1 || oneInFour == 3) {
      console.log("show nothing")
      this.setState({
        innerGif0: "",
        innerGif1: "",
        innerGif2: "",
        gifStyle: {
          visibility: "hidden"
        },
        titleStyle: {
          visibility: "hidden"
        }
      })
    } else {
      console.log("show title")
      this.setState({
        innerGif0: "",
        innerGif1: "",
        innerGif2: "",
        gifStyle: {
          visibility: "hidden"
        },
        titleStyle: {
          visibility: "visible"
        },
        counter: 0
      })
    }
    this.state.counter++
  } 

  componentDidMount() {
    setInterval(this.updateOuter, 20000);
    setInterval(this.updateInner, 5000);
  }

  sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  
  render() {
    return <div style={this.containerStyle}>
        {/* <div className='App'> */}
        <div class="title" style={this.state.titleStyle}>
          <center>
            <h1>overthinker</h1>
            <button><a href="https://ppvm.io">ppvmio</a></button>
            <button><a href="https://github.com/ppvmio/anxiety">src</a></button>
            <button><a href="https://github.com/PPVMIO/anxiety/releases">bin</a></button>
          </center>
        </div>
        <img class="inner0" style={this.state.gifStyle} src={this.state.innerGif0}></img>
        <img class="inner1" style={this.state.gifStyle} src={this.state.innerGif1}></img>
        <img class="inner2" style={this.state.gifStyle} src={this.state.innerGif2}></img>
      </div>
  }
}

export default App;
